import request from '@/utils/request';
import { PageResult } from '@/api/typing';


export async function query_adviser_sign_receipt_contract_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/query_adviser_sign_receipt_contract_list', params);
}

export async function view_receipt_contract_pdf(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/view_receipt_contract_pdf', params);
}

export async function customer_sign_contract(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/customer_sign_contract', params);
}

export async function customer_sign_contract_view(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/customer_sign_contract_view', params);
}

export async function query_receipt_contract_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/query_receipt_contract_list', params);
}

export async function query_receipt_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/query_receipt_list', params);
}