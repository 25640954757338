
import { createVNode, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import PageModel5 from '@/components/page-model_kefu/index.vue';
import {
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PictureOutlined,
  ShareAltOutlined,
} from '@ant-design/icons-vue';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import {
  customer_sign_contract,
  customer_sign_contract_view,
  query_receipt_contract_list,
  view_receipt_contract_pdf,
} from '@/api/receipt-model';
import { adviser_sign_contract_view } from '@/api/cerp-model';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const current_org = store.getters['user/current_org'];
    const receipt_contract_id = Number(route.params['id']);
    const contract_url: any = ref(null);
    const checked_contract: any = ref(false);
    const is_sign: any = ref(false);
    query_receipt_contract_list({
      org_id: current_org.id,
      ids: receipt_contract_id,
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        if (res.data[0].status === 2) {
          view_receipt_contract_pdf({
            org_id: current_org.id,
            receipt_contract_id: receipt_contract_id,
          }).then((res: any) => {
            contract_url.value = res;
          });
        } else if (res.data[0].status === 3 || res.data[0].status === 4) {
          is_sign.value = true;
          customer_sign_contract_view({
            org_id: current_org?.id,
            receipt_contract_id: receipt_contract_id,
          }).then((res: any) => {
            contract_url.value = res;
          });
          notification.error({
            message: t('page.Induction.message.reminder'),
            description:
              t('已完成签署'),
          });
        } else {
          notification.error({
            message: t('page.Induction.message.reminder'),
            description:
              t('当前合同不支持客户签署'),
          });
        }
      }

    });

    const handleSignContract = () => {
      Modal.confirm({
        title: t('page.Induction.Select_signing_method'),
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: t('page.Induction.text'),
        okText: t('page.Induction.Finger'),
        onOk() {
          return new Promise(resolve => {
            const modifyModal: any = reactive({
              visible: true,
              component: 'SignModal',
              title: t('page.Induction.Finger'),
              ok: (data: any) => {
                return new Promise(resolve1 => {
                  customer_sign_contract({
                    org_id: current_org?.id,
                    sign_nsfile_id: data.fileid,
                    receipt_contract_id: receipt_contract_id,
                  }).then((res: any) => {
                    if (res) {
                      resolve1(null);
                      is_sign.value = true;
                      message.success(t('page.Induction.Signature_completed'));
                      message.success(t('page.Induction.Signing_contract_generation'));
                      customer_sign_contract_view({
                        org_id: current_org?.id,
                        receipt_contract_id: receipt_contract_id,
                      }).then((res: any) => {
                        contract_url.value = res;
                      });
                    }
                  });
                  resolve(null);
                });
              },
              cancel: () => {
                resolve(null);
              },
            });
            modifyModal.visible = true;
            modalBox(modifyModal);
          });
        },
        onCancel() {
          return new Promise(resolve => {
            const editModal = reactive({
              visible: true,
              title: t('page.Induction.text'),
              component: 'form-modal',
              loading: false,
              form: {
                settings: {
                  labelAlign: 'right',
                  layout: 'vertical',
                  col: [],
                },
                fields: [
                  {
                    type: 'input',
                    name: 'sign_name',
                    label: 'Text',
                    label_i18n: 'page.Induction.text',
                    disabled: false,
                    allowClear: true,
                    inputType: 'text',
                    defaultValue: '',
                    placeholder: 'Text',
                    placeholder_i18n: 'page.Induction.text',
                  },
                ],
                rules: {},
                model: reactive({
                  sign_name: null,
                }),
              },
              options: {},
              ok: (payload: any) => {
                return new Promise(resolve1 => {
                  editModal.loading = true;
                  customer_sign_contract({
                    org_id: current_org?.id,
                    sign_name: payload.sign_name,
                    receipt_contract_id: receipt_contract_id,
                  }).then((res: any) => {
                    if (res) {
                      resolve1(null);
                      is_sign.value = true;
                      message.success(t('page.Induction.Signature_completed'));
                      message.success(t('page.Induction.Signing_contract_generation'));
                      adviser_sign_contract_view({
                        org_id: current_org?.id,
                        sign_name: payload.sign_name,
                        receipt_contract_id: receipt_contract_id,
                      }).then((res: any) => {
                        contract_url.value = res;
                      });
                    }
                  });
                  resolve(null);
                  editModal.loading = false;
                });
              },
              cancel: () => {
                resolve(null);
              },
            });
            modalBox(editModal);
          });
        },
      });
    };

    return {
      t,
      contract_url,
      checked_contract,
      is_sign,
      handleSignContract,
    };
  },
  components: {
    PageModel5,
    ShareAltOutlined,
    PictureOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
});
